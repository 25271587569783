// @ts-nocheck
import React, { useRef, useState } from 'react'
import { isEqual } from 'lodash'

import Select, { components } from 'react-select'
import ChevronDown from '@howdy/core/elements/Icons/ChevronDown'
import ChevronUp from '@howdy/core/elements/Icons/ChevronUp'
import MagnifyingGlass from '@howdy/core/elements/Icons/MagnifyingGlass'
import XCircleIcon from './Icons/XCircleIcon'
import Tooltip from './Tooltip'
import Info from './Icons/Info'

interface SelectType {
  value: string | number | null
}

export default function SelectInput({
  options,
  availableOptions,
  label,
  tooltip = '',
  isClearable = false,
  isSearchable = false,
  required = false,
  placeholder,
  disabled = false,
  error,
  isMulti = false,
  input,
  name,
  numberOptions = false,
  smallVersion = false,
  menuUp = false,
  sortOptions = true,
  ...restProps
}: {
  options: any[]
  availableOptions?: any[]
  label?: string
  isClearable?: boolean
  isSearchable?: boolean
  required?: boolean
  placeholder?: string
  disabled?: boolean
  error?: string
  input?: any
  name: string
  numberOptions?: boolean
  smallVersion?: boolean
  menuUp?: boolean
}) {
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const selectRef = useRef<HTMLInputElement>()
  const { onChange, value, ...restIput } = input

  const selectedValues = Array.isArray(value) ? value : value ? [value] : []

  const getDefaultValues = (options: any[]) => {
    return isMulti
      ? selectedValues?.map((v) => options.find(({ value: _value }) => _value === v))
      : options.find(({ value: _value }) => _value === value)
  }

  const defaultValue = getDefaultValues(options)

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator
        {...props}
        className='pointer-events-none text-howdy-primary-green'
      >
        {isSearchable ? (
          <MagnifyingGlass color='#449386' />
        ) : menuIsOpen ? (
          <ChevronUp color='#449386' />
        ) : (
          <ChevronDown color='#449386' />
        )}
      </components.DropdownIndicator>
    )
  }

  const sortLabels = (a: string, b: string) => {
    const nameA = numberOptions ? parseInt(a) : a.label?.toUpperCase() // ignore upper and lowercase
    const nameB = numberOptions ? parseInt(b) : b.label?.toUpperCase() // ignore upper and lowercase
    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }
    return 0
  }

  const formatOptionLabel = ({ value, label, icon }) => (
    <div className='flex items-center'>
      {icon && (
        <div className='flex items-center'>
          <div className='mr-2 h-[32px] w-[32px]'>{icon}</div>
        </div>
      )}
      {label}
    </div>
  )

  const MultiValueRemove = (props) => {
    return (
      <components.MultiValueRemove {...props}>
        <XCircleIcon />
      </components.MultiValueRemove>
    )
  }

  return (
    <div className='flex w-full flex-col'>
      {label && (
        <div className='flex h-5 flex-row gap-1 text-sm'>
          {required && <span className='font-regular mr-1 text-[#ED4F00]'>*</span>}
          {label}
          {tooltip && (
            <Tooltip tooltipContent={tooltip} className={'w-max max-w-[150px] text-center'}>
              <Info className='hover:text-[#449386]' width={16} height={16} />
            </Tooltip>
          )}
        </div>
      )}
      <>
        <div className={`${error ? 'mb-5' : ''}`}>
          <Select
            isClearable={isClearable}
            instanceId={label}
            isDisabled={disabled}
            ref={selectRef}
            maxMenuHeight={200}
            menuPlacement={menuUp ? 'top' : 'bottom'}
            className='font-regular relative rounded-lg text-black focus:ring-transparent md:text-2xl'
            isMulti={isMulti}
            components={{
              DropdownIndicator,
              MultiValueRemove,
            }}
            formatOptionLabel={formatOptionLabel}
            isSearchable={isSearchable}
            controlShouldRenderValue={!isSearchable || (isSearchable && !menuIsOpen)}
            onMenuOpen={() => setMenuIsOpen(true)}
            onMenuClose={() => {
              selectRef.current && selectRef.current.blur()
              setMenuIsOpen(false)
            }}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                border: error
                  ? 'solid 1px #ED4F00'
                  : state.isFocused
                  ? 'solid 1px #449386'
                  : 'solid 1px #C1C1C1',
                textDecoration: 'none',
                color: state.isDisabled ? '#646464' : state.isFocused ? '#449386' : 'black',
                backgroundColor: state.isDisabled ? '#C1C1C1' : 'white',
                borderRadius: '8px',
                boxShadow: 'none',
                padding: smallVersion || isMulti ? '0px 5px' : '7px 10px',
                minHeight: isMulti ? '56px' : 'unset',
                lineHeight: '22px',
                fontSize: '16px',
                '&:hover': {
                  border: error ? 'solid 1px #ED4F00' : 'solid 1px #449386',
                },
              }),
              placeholder: (styles, state) => ({
                ...styles,
                color: state.isDisabled
                  ? '#646464'
                  : error
                  ? '#ED4F00'
                  : state.isFocused
                  ? '#449386'
                  : '#C1C1C1',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }),
              singleValue: (styles) => ({ ...styles, color: 'text-howdy-teal' }),
              menu: (styles) => ({
                ...styles,
                borderRadius: '8px',
                boxShadow: '4px 4px 10px 0px rgba(0,0,0,0.1)',
                zIndex: 100,
              }),
              menuList: (styles) => ({ ...styles, borderRadius: '8px', margin: '2.5% 0' }),
              valueContainer: (defaultStyles) => ({
                ...defaultStyles,
                paddingLeft: 0,
                textOverflow: 'ellipsis',
                maxWidth: '90%',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }),
              indicator: () => ({ color: '#449386' }),
              indicatorSeparator: () => ({ display: 'none' }),
              option: (provided, state) => ({
                ...provided,
                fontSize: '16px',
                color: state.isSelected && !state.isFocused ? '#449386' : 'black',
                width: '95%',
                margin: '0 2.5%',
                borderRadius: '8px',
                backgroundColor: state.isFocused ? '#449386' : 'white',
                '&:hover': {
                  backgroundColor: '#449386',
                  color: 'white',
                },
              }),
              input: (base, state) => ({ ...base, '[type="text"]:focus': { boxShadow: 'none' } }),
              multiValue: (styles) => {
                return {
                  ...styles,
                  border: '1px solid #23286b',
                  borderRadius: '360px',
                  backgroundColor: 'white',
                  padding: '4px 6px',
                  margin: '1px',
                }
              },
              multiValueRemove: (styles) => ({
                ...styles,
                color: '#CB535D',
                fontSize: '50px',
                ':hover': {
                  backgroundColor: 'none',
                },
              }),
            }}
            options={
              availableOptions?.sort(sortOptions ? sortLabels : () => {}) ||
              options?.sort(sortOptions ? sortLabels : () => {})
            }
            placeholder={placeholder}
            value={isMulti ? defaultValue : options?.find(({ value: _value }) => _value === value)}
            onChange={(option: SelectType | SelectType[]) => {
              onChange(option?.map?.(({ value }) => value) || option?.value || undefined)
            }}
            {...restIput}
            {...restProps}
          />
        </div>
        {error && <p className='ml-3 mt-1 text-left text-xs text-[#ED4F00]'>{error}</p>}
      </>
    </div>
  )
}
