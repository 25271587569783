import React from 'react'

export default function Info({ color = 'currentColor', width = 30, height = 30, className = '' }) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15 26.25C21.2132 26.25 26.25 21.2132 26.25 15C26.25 8.7868 21.2132 3.75 15 3.75C8.7868 3.75 3.75 8.7868 3.75 15C3.75 21.2132 8.7868 26.25 15 26.25Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.0625 14.0625H15V20.625H15.9375'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.7656 11.0156C15.4128 11.0156 15.9375 10.491 15.9375 9.84375C15.9375 9.19654 15.4128 8.67188 14.7656 8.67188C14.1184 8.67188 13.5938 9.19654 13.5938 9.84375C13.5938 10.491 14.1184 11.0156 14.7656 11.0156Z'
        fill={color}
      />
    </svg>
  )
}
