// @ts-nocheck
import React from 'react'
import { Field } from 'react-final-form'
import { required } from '@howdy/core/lib/required'
import Input, { InputProps } from '@howdy/core/elements/Input'

export default function InputField({
  isRequired = false,
  errorMessage,
  label = '',
  placeholder,
  validate,
  name,
  format,
  parse,
  type,
  visibilityControlled = false,
  ...rest
}: InputProps & {
  validate?: (value: any) => void
  format?: (v: any) => any
  parse?: (v: any) => any
  errorMessage?: string
  isRequired?: boolean
  type?: string
  visibilityControlled?: boolean
  compact?: boolean
}) {
  const validateEmail = (value: any) => {
    if (type === 'email' && !/^\S+@\S+\.\S+$/.test(value)) {
      return 'Invalid email address'
    }
    return undefined
  }

  return (
    <Field
      name={name}
      parse={parse}
      format={format}
      validate={(value: any) => {
        const emailError = validateEmail(value)
        const requiredError = isRequired ? required(value, errorMessage) : undefined
        return emailError || requiredError
      }}
    >
      {({ input, meta }) => (
        <Input
          required={isRequired}
          label={label}
          placeholder={placeholder}
          error={meta.error && meta.touched ? meta.error : false}
          input={input}
          visibilityControlled={visibilityControlled}
          name={name}
          {...rest}
        />
      )}
    </Field>
  )
}
