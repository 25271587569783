import React, { ButtonHTMLAttributes, useState } from 'react'
import clsx from 'clsx'
import TrashIcon from './Icons/TrashIcon'
import RightChevron from './Icons/RightChevron'
import LeftChevron from './Icons/LeftChevron'
import PencilEdit from './Icons/PencilEdit'

export enum IconsEnum {
  trash,
  left,
  right,
  edit,
}

const Icons = {
  [IconsEnum.trash]: TrashIcon,
  [IconsEnum.left]: LeftChevron,
  [IconsEnum.right]: RightChevron,
  [IconsEnum.edit]: PencilEdit,
}

const TextColorClass = {
  [IconsEnum.trash]: 'text-howdy-warning-red',
  [IconsEnum.left]: 'text-howdy-white',
  [IconsEnum.right]: 'text-howdy-white',
  [IconsEnum.edit]: 'text-howdy-white',
}

const BorderColorClass = {
  [IconsEnum.trash]: 'border-howdy-warning-red',
  [IconsEnum.left]: 'border-black',
  [IconsEnum.right]: 'border-black',
  [IconsEnum.edit]: 'border-black',
}

const hoverTransitions = clsx('group-hover:bg-howdy-light-green', 'group-hover:text-white')

const activeTransitions = clsx('group-active:bg-howdy-primary-green', 'group-active:shadow-none')

const trashHoverTransitions = clsx('group-hover:bg-howdy-warning-red')

export default function RoundIconButton({
  type = 'button',
  disabled,
  onClick,
  icon,
  iconSize,
}: ButtonHTMLAttributes<any> & {
  icon: IconsEnum
  iconSize?: { width?: number; height?: number }
}) {
  const disabledClassNames = disabled ? 'bg-howdy-disabled text-howdy-gray' : ''
  const Icon = Icons[icon]
  const iconProps = iconSize ? iconSize : {}
  const [hovered, setHovered] = useState(false)

  return (
    <div className={`h-full w-full ${disabled ? '' : 'group'}`}>
      <button
        onClick={onClick}
        disabled={disabled}
        type={type}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        className={clsx(
          'flex h-full w-full items-center justify-center',
          'rounded-full',
          'transition-transform',
          'transition-shadow',
          'transition-colors',
          'duration-75',
          'ease-in',
          `border ${BorderColorClass[icon]}`,
          hoverTransitions,
          activeTransitions,
          disabledClassNames,
          icon === IconsEnum.trash ? trashHoverTransitions : '',
        )}
      >
        <div
          className={clsx(
            TextColorClass[icon],
            hovered && icon === IconsEnum.trash ? 'group-hover:text-white' : '',
          )}
        >
          <div>
            <Icon {...iconProps} />
          </div>
        </div>
      </button>
    </div>
  )
}
