import React, { ReactNode, useState } from 'react'
import Popover from '@howdy/core/elements/PopOver'

const Tooltip = ({
  tooltipContent,
  position = 'top',
  className = '',
  children,
}: {
  tooltipContent: any
  position?: string
  className?: string
  children: ReactNode
}) => {
  const [showTooltip, setShowTooltip] = useState(false)

  return (
    <Popover
      isVisible={showTooltip}
      content={tooltipContent}
      position={position}
      classes={className}
    >
      <div
        onMouseEnter={() => {
          setShowTooltip(true)
        }}
        onMouseLeave={() => {
          setShowTooltip(false)
        }}
        onTouchStart={() => {
          setShowTooltip(true)
        }}
      >
        {children}
      </div>
    </Popover>
  )
}

export default Tooltip
