// @ts-nocheck
export const upload = async (file: File) => {
  // TODO: return already uploaded files
  if (file.src || typeof file == 'string') {
    return file as { src: string }
  }
  const res = await fetch(`/api/upload-url`)
  const { url, fields } = await res.json()
  const formData = new FormData()

  Object.entries({ ...fields, file }).forEach(([key, value]: [string, string | Blob]) => {
    formData.append(key, value)
  })

  const upload = await fetch(url, {
    method: 'POST',
    body: formData,
    // referrerPolicy: 'no-referrer',
    mode: 'no-cors',
  })

  if (upload.ok) {
    console.log('Uploaded successfully!')
  } else {
    // console.error('Upload failed.', upload.statusText);
  }
  // console.log(url)
  return { src: url + fields.key }
}

export const uploadWithName = async ({ name, file }: { name: string; file: File }) => {
  // TODO: return already uploaded files
  if (!file) {
    return
  }
  if (file.src) {
    return file as { src: string }
  }
  const res = await fetch(`/api/upload-url`, {
    method: 'POST',
    body: JSON.stringify({
      name,
    }),
  })
  const { url, fields } = await res.json()
  const formData = new FormData()

  Object.entries({ ...fields, file }).forEach(([key, value]: [string, string | Blob]) => {
    formData.append(key, value)
  })

  const upload = await fetch(url, {
    method: 'POST',
    body: formData,
    // referrerPolicy: 'no-referrer',
    mode: 'no-cors',
  })

  if (upload.ok) {
    console.log('Uploaded successfully!')
  } else {
    // console.error('Upload failed.', upload.statusText);
  }
  // console.log(url)
  return { src: url + fields.key }
}
