import React from 'react'

export default function RightChevron({
  color = 'black',
  strokeWidth = '1.5',
  width = 11,
  height = 20,
}: {
  color: string
  strokeWidth: string
  width?: number
  height?: number
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 11 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.5 1.25L10.25 10L1.5 18.75'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
