import { useMemo } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import uniqBy from 'lodash/uniqBy'
import { Candidate } from './types/candidate'
import { upload } from './lib/upload-client'
import { Types } from './elements/Gallery'
import { errorToast, successToast } from './lib/toast'

export interface BaseEntity {
  name: string
  id: number
  createdAt: string
  createdBy: string
}

export const useSearchCandidates = () => {
  return useQuery<Candidate[]>(['api/search'])
}

export const useGetManagers = () => {
  const { data } = useSearchCandidates()
  return useMemo(
    () =>
      uniqBy(
        data?.map((d) => d.manager),
        'id',
      ).map((m) => ({ value: m?.id || 'empty', label: m?.name || 'Unassigned' })),
    [data],
  )
}

export const useMyCandidate = () => {
  return useQuery<Candidate>(['api/profile'])
}

export const useGetCandidate = (id?: string) => {
  return useQuery<Candidate>(['api/profile', id], {
    enabled: !!id,
  })
}

export const useGetCandidateBySfid = (sfid: string, enabled = true) => {
  return useQuery<Candidate>(['api/profile/salesforce', sfid], { enabled })
}

export const useGetPartners = ({ enabled }) => {
  return useQuery<{ name: string; id: number }[]>(['api/partners'], { enabled })
}

export const useGetUsers = ({ role }, { enabled }) => {
  return useQuery<{ name: string; id: number; email: string }[]>(['api/users?role=' + role], {
    enabled,
  })
}

export const useGetRoles = () => {
  return useQuery<BaseEntity[]>(['api/roles'])
}

export const useGetMainTech = () => {
  return useQuery<BaseEntity[]>(['api/mainTech'])
}

export const useGetSkills = () => {
  return useQuery<BaseEntity[]>(['api/skills'])
}

export const useGetRoster = () => {
  return useQuery<{ roster: any[] }, unknown, any[]>(['agreements/by_partner'], {})
}

export const useGetSourcingPipeline = ({ companyName }) => {
  return useQuery<{ data: any[] }, unknown, any[]>(
    [`recommendation/sourcingpipelines/${companyName}`],
    {
      enabled: !!companyName,
      select: ({ data }) => data,
    },
  )
}

export const useGetTeammates = () => {
  return useQuery<any[]>(['company/employees'])
}

export const createAccount = async (data) => {
  return await fetch('/api/partners', {
    method: 'POST',
    body: JSON.stringify(data),
  })
}

export const createOrder = async (data) => {
  return await fetch('/api/orderStore', {
    method: 'POST',
    body: JSON.stringify(data),
  })
}

export const editAccount = async (id, data) => {
  return await fetch('/api/partners/' + id, {
    method: 'PUT',
    body: JSON.stringify(data),
  })
}

export const createCandidate = ({ email }) => {
  return fetch('/api/profile', {
    method: 'POST',
    body: JSON.stringify({ email }),
  }).then((r) => r.json())
}

export const updateCandidate = (id, body) => {
  return fetch('/api/profile/' + id, {
    method: 'PUT',
    body: JSON.stringify(body),
  }).then((r) => r.json())
}

export const sortByDates = (a, b) => sortByDatesField('toDate')(a, b)

export const sortByDatesField = (field: string) => (a, b) => {
  if (!a[field] || new Date(a[field]) > new Date(b[field])) {
    return -1
  }

  if (new Date(a[field]) < new Date(b[field])) {
    return 1
  }

  return 0
}

export const uploadCandidate = async ({
  id,
  zipfiles: _zipfiles,
  files_photos: files,
  avatar: avatar_file,
  files_intro: video_files,
  education,
  career,
  courses,
  visible,
  archived,
  ...form
}) => {
  try {
    const avatar = avatar_file && (await upload(avatar_file))
    const files_photos = files && (await Promise.all(files.map(upload)))
    const zipfiles =
      _zipfiles &&
      (await Promise.all(
        _zipfiles.map(async ({ name, url }) => {
          return { name, url: { path: url.path, ...(await upload(url)) } }
        }),
      ))
    const files_intro =
      video_files &&
      (await Promise.all(video_files.map(upload))).map((v) => ({ ...v, type: Types.video }))

    career?.sort(sortByDates).map &&
      (await Promise.all(career.map(processInstitutionData.bind(null, 'company'))))
    courses?.sort(sortByDates)?.map &&
      (await Promise.all(courses.map(processInstitutionData.bind(null, 'education'))))
    education?.sort(sortByDates)?.map &&
      (await Promise.all(education.map(processInstitutionData.bind(null, 'education'))))

    // TODO: clean career, education, courses from extra data
    if (visible !== undefined) {
      await fetch('/api/profile/' + (id ?? '') + '/visible', {
        method: 'PUT',
        body: JSON.stringify({
          visible,
        }),
      })
    }

    if (archived !== undefined) {
      await fetch('/api/profile/' + (id ?? '') + '/archived', {
        method: 'PUT',
        body: JSON.stringify({
          archived,
        }),
      })
    }

    const first_name = form.first_name?.trim()
    const last_name = form.last_name?.trim()

    const result = await fetch('/api/profile/' + (id ?? ''), {
      method: id ? 'PUT' : 'POST',
      body: JSON.stringify({
        ...form,
        first_name,
        last_name,
        avatar,
        zipfiles,
        career,
        courses,
        education,
        files_intro,
        files_photos,
      }),
    }).then((r) => r.json())
    successToast({
      title: 'Success',
      message: 'Profile updated successfully',
    })
    return result
  } catch (e) {
    console.error(e)
    errorToast({
      title: 'Error',
      message: 'There was an error updating profile',
    })
  }
}

export const updateInstitution = async ({
  id,
  type,
  logo,
  description,
}: {
  id: string
  type: string
  logo?: string
  description?: string
}) => {
  if (!logo && !description) {
    return
  }
  const result = await fetch(`/api/institutions/${type}`, {
    method: 'PUT',
    body: JSON.stringify({ id, logo, description }),
  })
  return result.json()
}

async function processInstitutionData(type: 'company' | 'education', e: Record<string, any>) {
  const hasLogo = e.logo instanceof File
  const hasDescription = !!e.institutionDescription
  if (!hasLogo && !hasDescription) {
    return
  }
  const data: Partial<{
    logo: string
    description: string
  }> = {}
  if (hasLogo) {
    const { src } = await upload(e.logo)
    data.logo = src
  }
  if (hasDescription) {
    data.description = e.institutionDescription
  }
  await updateInstitution({
    type,
    id: e.institution,
    ...data,
  })
}

export const useGetCandidates = (ids: string[], ...args) => {
  return useQuery<Candidate[]>(['api/profiles', ...ids], ...args)
}

export const useMutateVisible = (id: string) => {
  const queryClient = useQueryClient()
  return useMutation<Candidate, unknown, { visible: boolean }>(
    ['api/profile', id],
    async ({ visible }) => {
      const response = await fetch(`/api/profile/${id}/visible/`, {
        method: 'PUT',
        body: JSON.stringify({ visible }),
      })
      return response.json()
    },
    {
      onSettled: () => {
        queryClient.refetchQueries(['api/profile', id])
        queryClient.refetchQueries(['api/profiles'])
        queryClient.refetchQueries(['api/search'])
      },
    },
  )
}

export const useShareToViewer = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (data) => {
      const response = await fetch('/sharedProfile', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json()
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['profiles'])
      },
    },
  )
}

export const useShareToCollaborator = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (data) => {
      const response = await fetch('/collaborator', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json()
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['profiles'])
      },
    },
  )
}

export const useRemoveCollaborator = (email, getUsers) => {
  const queryClient = useQueryClient()
  return useMutation<Candidate, unknown, { collaborator: string }>(
    async () => {
      const response = await fetch(`/collaborator/${email}`, {
        method: 'DELETE',
      })
      return response.json()
    },
    {
      onSettled: () => {
        queryClient.refetchQueries(['api/users'])
      },

      onSuccess: () => {
        successToast({
          title: 'Success',
          message: `You have just removed access to ${email}. If you want to give him access again, add him as a collaborator.`,
        })
        getUsers()
      },

      onError: () => {
        errorToast({
          title: 'Error',
          message:
            'We could not execute the action due to technical issues that we are investigating. If this persists, contact technical support.',
        })
      },
    },
  )
}

export const useRemoveViewer = ({ id, email, getUsers }) => {
  const queryClient = useQueryClient()
  return useMutation<Candidate, unknown, { collaborator: string }>(
    async () => {
      const body = {
        sharedProfileId: id,
      }
      const response = await fetch('/sharedProfile', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })
      return response.json()
    },
    {
      onSettled: () => {
        queryClient.refetchQueries(['api/users'])
      },

      onSuccess: () => {
        successToast({
          title: 'Success',
          message: `You have just removed viewer access to ${email}. If you want to give him access again, add him as a viewer.`,
        })
        getUsers()
      },

      onError: () => {
        errorToast({
          title: 'Error',
          message:
            'We could not execute the action due to technical issues that we are investigating. If this persists, contact technical support.',
        })
      },
    },
  )
}

export const getPartners = async (search?: string) => {
  try {
    const result = await fetch(`/api/partners?q=${search ?? ''}`)
    return result.ok ? result.json() : []
  } catch (error) {
    console.error(error)
  }

  return []
}

export const getPartnersSearch = async (search?: string) => {
  try {
    const result = await fetch(`/api/partners/search?q=${search ?? ''}`)
    return result.ok ? result.json() : []
  } catch (error) {
    console.error(error)
  }

  return []
}

export const getPartnersForOptions = async (input: string) => {
  return (await getPartners(input))?.map((p) => {
    const hasUsers = p.users.length > 0
    const hasUserInvites = p.users.length > 0
    const userLabel = hasUsers && `${p.users?.[0]?.email}`
    const userInviteLabel = hasUserInvites && `${p.userInvites?.[0]?.email}`
    const label = p.name || userLabel || userInviteLabel
    return { label, value: p.id }
  })
}

export const getPartnersForOptionsSearch = async (
  input: string,
): Promise<
  {
    label: string
    value: string
    icon: string | undefined
  }[]
> => {
  return (await getPartnersSearch(input))?.map((p) => ({
    label: p.name || p.users.find((u) => u.role.toLowerCase() === 'owner')?.email,
    value: p.id as string,
    users: p.users,
    userInvites: p.userInvites,
  }))
}

export const useGetEmployeesByCompanyId = (companyId?: string) => {
  return useQuery<any>([`api/companies/${companyId}/employees`], {
    enabled: !!companyId,
    select: (data) =>
      data?.map((p) => ({
        label: p.name,
        value: { id: p.id, employee_id: p.employee_id, name: p.name, country: p.country },
      })),
  })
}

const countriesMap = {
  AR: 'argentina',
  BR: 'brazil',
  CL: 'chile',
  CO: 'colombia',
  MX: 'mexico',
  PE: 'peru',
  UY: 'uruguay',
  US: 'usa',
}

export const useGetStoreProducts = (countryName?: string) => {
  const country = countriesMap[countryName] || countryName
  return useQuery<any>([`api/products/${country}`], {
    enabled: !!countryName,
    select: (data) =>
      data?.map((p) => ({
        label: `${p.name} ($${p.price})`,
        name: p.name,
        value: p.id,
        price: p.price,
        description: p.description,
        shortDescription: p.shortDescription,
      })),
  })
}

export const getInstitutions = async (search?: string, type?: string) => {
  const result = await fetch(`/api/institutions/${type}?q=${search ?? ''}`)
  return result.json()
}

export const useGetCompanies = () => {
  return useQuery<Candidate[]>(['api/companies'])
}

export const createNewPartner = async (email) => {
  const result = await fetch('/api/partners', {
    method: 'POST',
    body: JSON.stringify({ email: email }),
  })
  if (!result.ok) {
    throw Error(`Unable to create new partner: ${(await result.json()).message || 'unknown error'}`)
  }
  return result.json()
}

export const createNewInstitution = async (name, type) => {
  const result = await fetch(`/api/institutions/${type}`, {
    method: 'POST',
    body: JSON.stringify({ name }),
  })
  if (!result.ok) {
    throw Error(
      `Unable to create new institution: ${(await result.json()).message || 'unknown error'}`,
    )
  }
  return result.json()
}

export const listRecommendations = async ({
  skip = 0,
  take = 100,
  search,
  sort = 'createdAt',
  order = 'desc',
}: {
  skip?: number
  take?: number
  search?: string
  sort?: string
  order?: 'asc' | 'desc'
}) => {
  const params = new URLSearchParams({
    skip: String(skip),
    take: String(take),
    sort,
    order,
  })
  if (search) {
    params.append('q', search)
  }
  const url = `/api/recommendations?${params.toString()}`
  const result = await fetch(url)
  return result.json()
}

export const getRecommendationsByCompanyId = async ({
  id,
  skip = 0,
  take = 20,
}: {
  id: string
  skip?: number
  take?: number
}) => {
  const params = new URLSearchParams({
    id,
    skip: String(skip),
    take: String(take),
  })

  const url = `/api/recommendations/company?${params.toString()}`
  const result = await fetch(url)
  return result.json()
}

export const getRecommendationsByEngineerId = async ({
  id,
  skip = 0,
  take = 20,
}: {
  id: string
  skip?: number
  take?: number
}) => {
  const params = new URLSearchParams({
    id,
    skip: String(skip),
    take: String(take),
  })

  const url = `/api/recommendations/engineer?${params.toString()}`
  const result = await fetch(url)
  return result.json()
}

export const listRoles = async () => {
  const result = await fetch('/api/roles')
  return result.json()
}

export const listMainTech = async () => {
  const result = await fetch('/api/mainTech')
  return result.json()
}

export const getEmployeesFromCompanyName = async (companyName: string) => {
  const result = await fetch(`/api/companies/${companyName}/employees`)
  return result.json()
}

export const getEmployeesForOptionsSearch = async (input: string) => {
  if (!input) {
    return []
  }
  return (await getEmployeesFromCompanyName(input))?.map((p) => ({
    label: p.name,
    value: {
      id: p.id,
      name: p.name,
      country: p.country,
    },
  }))
}

export const sendTTIStats = async (data) => {
  try {
    return await fetch('/api/tti', {
      method: 'POST',
      body: JSON.stringify(data),
    })
  } catch (error) {
    console.error(error)
  }

  return []
}
