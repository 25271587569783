// @ts-nocheck
import React, { useState, useEffect, useCallback } from 'react'
import cn from 'classnames'
import RoundIconButton, { IconsEnum } from './RoundIconButton'
import { useCarousel } from '../contexts/carouselContext'
import setDisableTransfrom from '../actions/setDisableTransform'

export enum Types {
  video = 'video',
  image = 'image',
}

export enum VideoTypes {
  youtube = 'youtube',
  other = 'other',
}

export type Tile = {
  type: Types
  videoType?: VideoTypes
  src: string
}

const ModalGallery = ({
  tile,
  toggleModalOpen,
  previousTile,
  nextTile,
}: {
  tile: Tile
  toggleModalOpen: (e?: any) => void
  previousTile: (e?: any) => void
  nextTile: (e?: any) => void
}) => {
  useEffect(() => {
    const handler = (e) => {
      const key = e.keyCode

      if (key === 37) {
        previousTile(e)
      } else if (key === 39) {
        nextTile(e)
      } else if (key === 27) {
        toggleModalOpen()
      }
    }

    document.addEventListener('keydown', handler)

    return () => {
      document.removeEventListener('keydown', handler)
    }
  }, [tile])

  return (
    <div
      className='fixed left-0 top-0 z-[100] flex h-full w-full items-center justify-center bg-black bg-opacity-80'
      onClick={toggleModalOpen}
    >
      <div className='mr-2 h-12  w-12'>
        <RoundIconButton icon={IconsEnum.left} onClick={(e) => previousTile(e)} />
      </div>

      <div className='w-1/3' onClick={(e) => e.stopPropagation()}>
        {tile.type === Types.video ? (
          tile?.videoType === VideoTypes.youtube ? (
            <iframe
              className='w-full'
              style={{
                aspectRatio: ' 16/9',
              }}
              src={`https://www.youtube.com/embed/${tile.src}`}
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
              allowFullScreen
            />
          ) : (
            <video
              className='w-full'
              style={{
                aspectRatio: ' 16/9',
              }}
              controls
            >
              <source src={tile.src} />
              Your browser does not support HTML video.
            </video>
          )
        ) : (
          <img className='h-full w-full object-cover' src={tile.src} />
        )}
      </div>
      <div className='ml-2 h-12 w-12'>
        <RoundIconButton icon={IconsEnum.right} onClick={(e) => nextTile(e)} />
      </div>
    </div>
  )
}

const Gallery = ({ tiles = [] }: { tiles: Tile[] }) => {
  const tilesLength = tiles.length
  const [mainTileIndex, setMainTileIndex] = useState(0)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const carouselContext = useCarousel()
  const handleTileClick = (index) => {
    setMainTileIndex(index)
  }

  const toggleModalOpen = () => {
    if (carouselContext) {
      carouselContext.dispatch(setDisableTransfrom(!isModalOpen))
      setTimeout(() => {
        setIsModalOpen(!isModalOpen)
      }, 10)
    } else {
      setIsModalOpen(!isModalOpen)
    }
  }

  const nextTile = (e) => {
    if (e) {
      e.stopPropagation()
    }
    const possibleNewIndex = mainTileIndex + 1
    setMainTileIndex(possibleNewIndex > tilesLength - 1 ? 0 : possibleNewIndex)
  }

  const previousTile = (e) => {
    if (e) {
      e.stopPropagation()
    }
    const possibleNewIndex = mainTileIndex - 1

    setMainTileIndex(possibleNewIndex < 0 ? tilesLength - 1 : possibleNewIndex)
  }

  return (
    <>
      <div className='h-[232px]n flex w-full flex-col justify-between gap-3'>
        <div className='relative h-[180px] cursor-pointer overflow-hidden rounded-lg bg-black'>
          <>
            <div
              onClick={toggleModalOpen}
              className='absolute left-0 top-0 z-10 h-full w-full'
            ></div>
            {tiles[mainTileIndex]?.type === Types.video ? (
              tiles[mainTileIndex]?.videoType === VideoTypes.youtube ? (
                <iframe
                  className='h-full w-full'
                  src={`https://www.youtube.com/embed/${tiles[mainTileIndex].src}`}
                />
              ) : (
                <video className='h-full w-full'>
                  <source src={tiles[mainTileIndex].src} />
                  Your browser does not support HTML video.
                </video>
              )
            ) : (
              <img className='h-full w-full object-cover' src={tiles[mainTileIndex]?.src} />
            )}
            {tiles[mainTileIndex]?.type === Types.video ? (
              tiles[mainTileIndex].videoType !== VideoTypes.youtube && (
                <div className='absolute left-0 top-0 flex h-full w-full items-center justify-center'>
                  <img src='/play-circle.svg' className='h-11 w-11' />
                </div>
              )
            ) : (
              <img src='/arrows-out.svg' className='absolute bottom-0 right-0' />
            )}
          </>
        </div>
        <div className='relative w-[75vw] overflow-x-auto md:w-full'>
          <div className={`flex gap-3`} style={{ width: `${tiles.length * (40 + 12)}px` }}>
            {tiles.map((tile, index) => (
              <div
                className={cn(
                  'relative h-10 w-10 cursor-pointer overflow-hidden rounded',
                  'hover:outline hover:outline-2 hover:outline-howdy-teal',
                  {
                    'outline outline-2 outline-howdy-teal': index === mainTileIndex,
                  },
                )}
                onClick={() => handleTileClick(index)}
                key={index}
              >
                {tile.type === Types.video ? (
                  <div className='absolute left-0 top-0 flex h-full w-full items-center justify-center bg-howdy-light-gray'>
                    <img src='/play-circle.svg' className='h-7 w-7' />
                  </div>
                ) : (
                  <span
                    className='absolute left-0 top-0 h-full w-full bg-cover bg-center object-cover'
                    style={{ backgroundImage: `url(${tile.src})` }}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      {isModalOpen && (
        <ModalGallery
          tile={tiles[mainTileIndex]}
          toggleModalOpen={toggleModalOpen}
          previousTile={previousTile}
          nextTile={nextTile}
        />
      )}
    </>
  )
}

export default Gallery
