import { createContext, useContext, useReducer } from 'react'
import produce from 'immer'

export const CarouselContext = createContext()

export const carouselInitialState = {
  disableTransform: false,
}

const reducer = (draft, action) => {
  switch (action.type) {
    case 'SET_DISABLE_TRANSFORM':
      draft.disableTransform = action.payload
      break
    default:
  }
}

export const carouselReducer = produce(reducer)

export const CarouselProvider = ({ children }) => {
  const [carouselState, dispatch] = useReducer(carouselReducer, carouselInitialState)
  return (
    <CarouselContext.Provider value={{ carouselState, dispatch }}>
      {children}
    </CarouselContext.Provider>
  )
}

export function useCarousel() {
  const context = useContext(CarouselContext)
  return context
}
