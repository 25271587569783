import React from 'react'
import Eye from './Icons/Eye'
import EyeSlash from './Icons/Eye-slash'
import Circle from './Circle'

export interface VisibilityEyeProps {
  visible?: boolean
  size?: number
  name?: string
  onClick?: () => void
}

function VisibilityEye({ visible, name, size = 20, onClick }: VisibilityEyeProps) {
  return (
    <div onClick={onClick} className='cursor-pointer' data-name={name}>
      {visible ? (
        <Circle borderColor='#449386' bgColor='#449386' size={size + 10}>
          <Eye color='white' width={size} height={size} />
        </Circle>
      ) : (
        <Circle borderColor='#646464' bgColor='#646464' size={size + 10}>
          <EyeSlash color='white' width={size} height={size} />
        </Circle>
      )}
    </div>
  )
}

export default VisibilityEye
