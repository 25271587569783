import React, { PropsWithChildren } from 'react'
import clsx from 'clsx'

function Circle({
  children,
  className,
  size = 55,
  bgColor = 'white',
  borderColor = '#DCDCDC',
  hasBorder = true,
  shadow = false,
}: PropsWithChildren<{
  size?: number
  className?: string
  bgColor?: string
  borderColor?: string
  hasBorder?: boolean
  shadow?: boolean
}>) {
  return (
    <div
      className='relative inline-block'
      style={{
        height: size,
        width: size,
        minWidth: size,
      }}
    >
      <div
        className={clsx(
          'rounded-full',
          hasBorder && 'border',
          'z-10 flex h-full w-full items-center justify-center text-howdy-primary-green',
          className,
        )}
        style={{
          borderColor: hasBorder ? borderColor : 'transparent',
          backgroundColor: bgColor,
          boxShadow: shadow ? '0px 0px 12px 0px rgba(0, 0, 0, 0.16)' : 'none',
        }}
      >
        {children}
      </div>
    </div>
  )
}

export default Circle
